/* eslint-disable global-require */
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-lightbox/style.css';
import 'video.js/dist/video-js.css';
import '@/assets/css/vendor/bootstrap.min.css';
import '@/assets/css/vendor/bootstrap.rtl.only.min.css';
import '@/assets/css/common.css';
import { getCurrentColor, setCurrentColor } from '@/helpers/Utils';
import es from 'date-fns/locale/es';
import { registerLocale } from 'react-datepicker';

registerLocale('es', es);

import { defaultColor } from '@/constants/defaultValues';

let color = getCurrentColor() || defaultColor;
setCurrentColor(color);

const render = () => {
  if (typeof color !== 'string') color = 'light.supernova';
  import(`./assets/css/sass/themes/gogo.light.supernova.scss`).then(() => {
    import('@/AppRenderer' /* @vite-ignore */);
  });
};
render();
